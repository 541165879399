import Vue from 'vue/dist/vue.min';
import {demo} from './components/demo';

export function vm(){
    const v = new Vue({
        el: '#site-wrapper',
        components: {
            demo: demo()
        }
    });
};