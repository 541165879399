import ScrollMagic from 'scrollmagic';
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.polyfills.min';
import Rellax from 'rellax';
export function scrolEvents(controller , scene){
	const siteHeader = document.getElementById('site-header');
    if(siteHeader){
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: 'body',
                offset: window.innerHeight * 1.5
            })
            .setClassToggle(siteHeader,'active').addTo(controller)
        )
    };
	
	const sitePageTop = document.getElementById('pagetop');
    if(sitePageTop){
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: 'body',
                offset: window.innerHeight * 1.5
            })
            .setClassToggle('sitePageTop','active').addTo(controller)
        )
    }
	
	const scroll_anime_name = 'scroll-anime';
	const scroll_anime_elem = document.getElementsByClassName(scroll_anime_name);
	if(scroll_anime_elem.length > 0){
		for(let i = 0; i < scroll_anime_elem.length; i++){
			let scroll_node = scroll_anime_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: scroll_node,
					offset: -300
				})
				.reverse(false)
				.setClassToggle(scroll_node,'_active').addTo(controller)
			)
		}
	}

    if(document.getElementsByClassName('rellax').length > 0){
        const rellax = new Rellax('.rellax',{
            center:true
        });
    }
    if(document.getElementsByClassName('rellax-no').length > 0){
        const rellax_no = new Rellax('.rellax-no',{
            center:false
        });
    }

    const scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
        offset: 0
    });
	
}